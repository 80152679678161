import { twMerge } from "tailwind-merge";
import Heading from "./layout/Heading";
import Card from "./layout/Card";
import Price from "./display/Price";
import PriceRRP from "./display/PriceRRP";
import Availability from "./display/Availability";
import { Link } from "@remix-run/react";
import type { RemixLinkProps } from "@remix-run/react/dist/components";
import type { ProductCardType } from "~/graphql/fragments/types";
import ProductImage from "./display/ProductImage";
import FreeShippingBadge from "./display/FreeShippingBadge";

export interface Props extends Omit<RemixLinkProps, "to"> {
  product: ProductCardType;
  size?: "sm" | "md";
  look?: "grid" | "list";
}

const ProductCard: React.FC<Props> = ({
  product,
  size = "md",
  look = "grid",
  className,
  ...props
}) => {
  return (
    <Link
      to={product.url as string}
      className={twMerge("", className)}
      {...props}
      prefetch="intent"
    >
      <Card size={size} className={twMerge(look === "list" && "flex gap-8")}>
        <ProductImage
          media={product.image}
          className={twMerge(
            "aspect-square w-full object-contain object-center",
            look === "list" && "aspect-auto w-[128px] sm:w-[192px]",
          )}
          // image width will be max. 368px on big screens
          // 192px on small screens
          // 128px on very small screens
          srcSet={[
            {
              options: { width: 800 },
              size: "800w",
            },
            {
              options: { width: 400 },
              size: "400w",
            },
            {
              options: { width: 200 },
              size: "200w",
            },
          ]}
        />
        {look === "grid" && <hr className="my-4" />}
        <div className={twMerge(look === "grid" && "contents")}>
          <Heading tag="h3" className="text-blu-500 font-medium">
            {product.name}
          </Heading>
          <div className="my-3 flex flex-col gap-1">
            {product.rrp ? (
              <PriceRRP
                price={
                  (product.allowTaxExemption
                    ? product.price
                    : product.priceGross) ?? 0
                }
                rrp={
                  (product.allowTaxExemption
                    ? product.rrp
                    : product.rrpGross) ?? 0
                }
              />
            ) : null}
            <Price
              value={
                (product.allowTaxExemption
                  ? product.price
                  : product.priceGross) ?? 0
              }
              className="font-medium"
            />
          </div>
          <Availability inStock={!!product.inStock} />
          <FreeShippingBadge
            freeShipping={!!product.freeShipping}
            className="my-2"
          />
        </div>
      </Card>
    </Link>
  );
};
export default ProductCard;
